import { render, staticRenderFns } from "./UnitsEditComponent.vue?vue&type=template&id=1688fbe1&scoped=true"
import script from "./UnitsEditComponent.vue?vue&type=script&lang=js"
export * from "./UnitsEditComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1688fbe1",
  null
  
)

export default component.exports