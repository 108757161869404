<template>
    <v-container fluid class="ma-0 pa-0">
        <v-card outlined elevation="0">
            <v-card-title class="headline">
                {{ $t('erp.lang_editUnit') }}
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col lg="4" md="6" sm="6">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="unit.name"
                                          :label="$t('erp.lang_unitName')"
                                          :rules="[v => !!v || $t('generic.lang_requiredField')]"
                                          autocomplete="off"
                                          required
                            ></v-text-field>
                        </v-col>
                        <v-col lg="4" md="6" sm="6">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="unit.symbol"
                                          :label="$t('erp.lang_unitSymbol')"
                                          :rules="[v => !!v || $t('generic.lang_requiredField')]"
                                          autocomplete="off"
                                          required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="space-between">
                        <v-col cols="12">
                            <v-divider/>
                        </v-col>
                        <v-col cols="12">
                            <v-subheader>{{ $t('erp.lang_conversionMainUnit') }}</v-subheader>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="4" md="6" sm="6">
                            <v-select outlined
                                      :label="$t('erp.lang_mainUnit')"
                                      :items="filteredMainUnits"
                                      item-text="unit_name"
                                      item-value="id"
                                      v-model="mainUnit.id"
                            ></v-select>
                        </v-col>
                        <v-col lg="4" md="6" sm="6">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="mainUnit.value"
                                          :label="$t('erp.lang_mainUnitValue')" :rules="[v=> !!v && Number(v)>0]"
                                          autocomplete="off"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="end">

                        <v-btn color="error"
                               :loading="loading"
                               class="ma-0 mr-2"
                               large
                               :disabled="!valid"
                               @click="deleteData()"
                        >
                            {{ $t('generic.lang_delete') }}
                        </v-btn>
                        <v-btn color="success"
                               :loading="loading"
                               class="ma-0"
                               large
                               :disabled="!valid"
                               @click="update()"
                        >
                            {{ $t('generic.lang_edit') }}
                        </v-btn>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns"

export default {
    name: "UnitsEditComponent",

    mixins: [mixin],

    data() {
        return {
            valid: false,
            loading: false,
            mainUnits: [],
            id: null,
            unit: {
                name: "",
                symbol: "",
            },
            mainUnit: {
                id: 0,
                value: 1,
            },
        }
    },

    computed: {
      filteredMainUnits() {
        if(!this.mainUnits || !this.mainUnits.length){
          return [];
        }
        let filtered=[];
        filtered=this.mainUnits.map( unit => {
          if(!unit && unit.unit_name.length === 0)
            return;

          if(unit.unit_name.includes("lang_"))
            unit.unit_name = this.$t('erp.'+unit.unit_name);

          return unit;
        })

        return filtered;
      }
    },

    methods: {
      getMainUnits() {
        let self=this;
        this.axios.post("get/erp/units/")
            .then(res => {
              if (res.data.STATUS ==='SUCCESS' ) {
                self.mainUnits=[];
                self.mainUnits.push({
                  id: 0,
                  unit_name:  self.$t('generic.lang_setAsMain')
                });

                self.mainUnits.push(...res.data.units);
              }else{
                Events.$emit("showSnackbar", {
                  message: self.$t('erp.lang_errorWhileGettingMainUnits'),
                  color: "error"
                });
              }
            }).catch(err=>{
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        })
      },
        getUnit() {
            this.axios.post("get/erp/editUnit/", {unitID: this.id})
                .then(res => {
                    if (res.status) {
                        let data = res.data.formfillData.textFields
                        this.mainUnit.id = data.parentUnitID;
                        this.mainUnit.value = data.unit2OneParentUnit;
                        this.unit.name = data.unit_name;
                        this.unit.symbol = data.unit_sign;
                    }
                })
        },
        update() {
            this.axios.post("update/erp/unit/", {
                unitID: this.id,
                unitName: this.unit.name,
                unitSymbol: this.unit.symbol,
                mainUnitID: this.mainUnit.id,
                mainUnitValue: this.mainUnit.value,
            })
                .then(res => {
                    if (res.status) {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_success'),
                            color: "success"
                        });
                    } else {

                    }
                    this.$router.go(-1);
                }).catch(err => {

            })
        },
        deleteData(idsToDelete = []) {
            let self = this;
            this.$swal({
                title: this.$t("erp.lang_deleteUnitHead"),
                text: this.$t("erp.lang_deleteUnitBody"),
                icon: "warning",
                cancelButtonText: this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_delete'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                        idsToDelete = [];
                        idsToDelete.push(parseInt(this.id));
                    }
                    this.axios.post("delete/erp/unit/", {
                        unitID: idsToDelete
                    }).then((res) => {
                        if (res.status === 200) {
                            Events.$emit("showSnackbar", {
                                message: this.$t("erp.lang_deleteUnitConfirm"),
                                color: "success"
                            });

                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        }
                        this.$router.go(-1);
                    });
                },
                allowOutsideClick: () => !this.$swal.isLoading,
            });
        },
        handle() {
            if (this.mainUnit.id === 0) {
                this.mainUnit.value = 1;
            }
        }
    },
    mounted() {
        this.getMainUnits();
        this.id = this.$route.params.id;
        this.getUnit();
    },

}
</script>

<style scoped>

</style>